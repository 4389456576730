/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    CreatorsRow.scss

    Stylings for the Creators Credit Rows
*/

.CreatorsRow {
    &-description {
        padding: 48px 0 20px;
        font: 400 16px 'Nunito Sans', sans-serif;
    }

    &-people {
        padding-bottom: 8px;
    }
}