/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    Footer.scss

    Stylings for the Wick Footer
*/

#Footer {
    &-container {
        //display: flex;
        justify-content: center;
        position: relative;
        color: black; //white;
        font: 600 14px 'Nunito Sans', sans-serif;
        background-color: #1EE29A;
    }

    &-copyright {
        display: block;
        //position: absolute;
        //right: 0;
        margin: 0 auto;
        padding-right: 24px;
        padding-bottom: 1em;
    }

    &-content {
        padding: 0;
        height: 1em;
    }

    &-notices {
        display: flex;
        flex-direction: row; 
    }

    &-image {
        display: flex; // force images to sit next to each other
        position: absolute;
        bottom: 0;
        // center images
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.15;
    }

    &-imageContainer {
        position: absolute;
        left: 0;
        bottom: 20px;
        z-index: -1; // force behind the Page title and Nav Bar
        width: 100%;
        height: 100%;
        overflow-x: hidden; // don't show parts of image offscreen
    }
}

.Footer {
    &-item {
        padding: 0 28px;
    }

    &-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &-link-item {
        padding: 8px; 
        color: black; //white;
    }

    &-link {
        color: black; //white;
    }
    &-link:hover{
        color: black;
    }
}