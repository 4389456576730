/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    UpdateCard.scss

    Stylings for the Update Info Tab
*/

.UpdateCard {
    &-button {
        margin-top: 12px;
    }

    &-container {
        text-align: left;
        font-family: 'Nunito Sans', sans-serif;
        margin: 20px 0;
        border-width: 0;
        box-shadow: 2px 3px #B4ECE6;
    }

    &-text {
        font-size: 18px;
        font-weight: 400;
    }

    &-version {
        font-size: 25px;
        font-weight: 700;
    }
}