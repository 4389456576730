/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    Creator.scss

    Stylings for the Creator Card (name and picture)
*/

.Creator {
    &-name {
        font: 600 18px 'Nunito Sans', sans-serif;
        padding-top: 12px;
        line-height: 20px;
    }

    &-picture {
        border-radius: 50%;
        border: 4px solid #1EE29A;
        width: 75%;
    }
}