/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    Wick.scss

    Master stylings for Wick
*/

.Wick {
    position: relative; // set to work with background style
    text-align: center;

    // Center the content of the page
    &-row {
        justify-content: center;

        @media screen and (max-width: 575px) {
            padding-left: 28px;
            padding-right: 28px;
        }
    }

    // Set in this fashion so the header image isn't hidden
    &-background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        background-color: #F3F8F7;
    }
}