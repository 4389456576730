/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    ContentSection.scss

    Stylings page content accompanied by a section title
*/

.ContentSection {
    &-body {
        font: 400 18px 'Nunito Sans', sans-serif;

        &--large {
            font-size: 18px;
        }

        &--small {
            font-size: 16px;
            line-height: 20px;
        }
    }

    &-icon {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 4px;

        &--iconOffset {
            position: absolute;
            overflow: hidden;
            left: 50%;
            transform: translate(-50%, -48px);
            max-width: 100%;
        }

        &--mobile {
            @media screen and (min-width: 992px) {
                display: none;
            }
        }
    }

    &-padding {
        padding-bottom: 24px;
    }

    &-titlePadding {
        padding-bottom: 12px;
    }

    &-title {
        text-align: center !important;

        font: 400 30px 'Nunito Sans', sans-serif;

        &--semibold {
            display: inline-block;
            font: 600 30px 'Nunito Sans', sans-serif;
        }

        &--large {
            font: 300 40px 'Nunito', sans-serif;
        }

        &--small {
            font-size: 26px;
        }
    }

    &-underline {
        margin: 12px auto 16px;

        &--large {
            margin: 16px auto 20px;
        }

        &--small {
            margin: 4px auto 8px;
        }
    }

    &-underline--small,
    &-underline,
    &-underline--large {
        width: 48px;
        border: solid 2px #1EE29A;
    }
}