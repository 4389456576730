/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    SocialMediaIcon.scss

    Stylings for contact links
*/

.SocialMediaIcon {
    &-container {
        margin: 0 6px;
        position: relative;
    }

    &-highlight,
    &-icon {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
        max-width: 30px;
        max-height: 30px;
    }

    &-highlight {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;

        &:hover {
            opacity: 1;
        }
    }

    &-icon {
        width: 100%;
        opacity: 1;

        &:hover {
            opacity: 0;
        }
    }
}