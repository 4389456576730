/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    WickRouter.scss

    Stylings for the Wick Nav Bar
*/

#WickRouter {
    &-activeItem {
        font: 600 16px 'Nunito Sans', sans-serif;
        color: #3E3E3E !important;

        &--brand {
            color: #015642 !important; //#019875 !important;
        }
    }

    &-container {
        margin-bottom: 48px;
        position: sticky;
        top: 0;
        z-index: 1;
    }
}

.WickRouter {
    &-background {
        background-color: #F3F8F7 !important;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        box-shadow: 0px 2px 10px #EAEAEA;
    }

    &-background--hidden {
        display: none;
    }

    &-brand--wide,
    &-brand--narrow {
        font: 600 22px 'Nunito', sans-serif;
        color: #015642 !important; //#019875 !important;
        transition: color 0.5s;

        &:hover {
            color: #014B3A !important; //#01C094 !important;
        }
    }

    &-brand--wide {
        margin: 0 24px;

        // hide for narrow screens
        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    &-brand--narrow {
        // hide for wide screens
        align-self: center;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8px;
        margin: 0;

        @media screen and (min-width: 768px) {
            display: none;
        }
    }

    &-navItem {
        margin: 0 16px;
        padding: 10px 8px 6px;
        font: 400 16px 'Nunito Sans', sans-serif;
        color: #005557 !important; //#007a7c !important;
        transition: color 0.5s;

        @media screen and (max-width: 767px) {
            text-align: center;
            margin: 0;
        }

        &:hover {
            color: #004A4D !important; //#3D9B91 !important;
        }
    }

    &-toggle {
        font: 600 22px 'Nunito', sans-serif;
        width: 56px;
    }
}