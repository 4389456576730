/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    WickCardDeck.scss

    Stylings for the Wick Card Deck
*/

.WickCardDeck {
    &-linkButton {
        &:hover {
            text-decoration: none;
        }
    }

    &-paddingContainer {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}