/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    WickInlineLink.scss

    Stylings for inline links
*/

.WickInlineLink {
    &-container {
        color: #0B5B3E; //#1EE29A;
        font-weight: 600;
        transition: color 0.5s;

        &:hover {
            color: #094931; //#1BC687;
            //text-decoration: none;
        }
    }
}