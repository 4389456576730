/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    WickCarousel.scss

    Stylings for Embedded Content
*/

.WickEmbed {
    &-container div {
        border-radius: 3px;
        box-shadow: 2px 3px #B4ECE6;
    }
}

