.terms-body {
    p {
        text-align: left;
    }
    
    text-align: left; 
}

ul.privacy-links {
    li {
        text-align: left; 
    }
}

i.privacy-italic-header {
    width: 100%; 
    text-align: left; 
}

h3 {
    text-align: left;
}

