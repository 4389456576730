/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    WickCardModal.scss

    Stylings for the Wick Card Modals
*/

.WickCardModal {
    &-body,
    &-footer,
    &-header {
        padding-left: 60px;
        padding-right: 60px;

        @media screen and (max-width: 991px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 575px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &-close {
        position: absolute;
        right: 0px;
        top: 18px;
        left: 95%;
        transform: translateX(-50%);
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        opacity: 1;
        color: #106546; //#1EE29A;
        transition: 0.5s;

        &:hover, &:focus {
            color: #0B563B !important; //#1BC687 !important;
        }
    }

    &-download {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        color: #1EE29A;
        transition: 0.5s;

        &:hover {
            color: #1BC687 !important;
        }
    }

    &-container {
        text-align: center;
        font-family: 'Nunito Sans', sans-serif;
    }

    &-description {
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        padding-left: 16px;
    }

    &-embed div {
        border-radius: 3px;
        box-shadow: 2px 3px #B4ECE6;
    }

    &-footer {
        display: flex;
        flex-direction: column;
    }

    &-infoContainer {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        padding-bottom: 15px;
        border: none;
    }

    &-header {
        justify-content: center;
        padding-bottom: 0;
        border: none;
    }

    &-number {
        color: #000000; //#C3C3C3;
        font-size: 14px;
        font-weight: 400;
    }

    &-tag {
        padding: 2px 16px;
        margin: 0 4px 8px;
        border-radius: 3px;
        background-color: #1EE29A;
        color: black; //white;
        font-size: 14px;
        font-weight: 400;
    }

    &-tagContainer {
        display: flex;

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }
    }

    &-time {
        margin: 0 4px;
        text-align: left;
        font-size: 16px;
        font-weight: 600;
    }

    &-title {
        font-size: 18px;
        font-weight: 600;
    }

    &-underline {
        width: 48px;
        border: solid 2px #1EE29A;
        margin: 8px auto;
    }
}
