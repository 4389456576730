/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    PreviewCard.scss

    Stylings for the Wick Padding Cards
*/

.WickPaddingCard {
    background-color: #F3F8F7;
    border-width: 0;
    margin-bottom: 0 !important;
}