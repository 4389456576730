/*
    Alex Yixuan Xu (ayxx@nyu.edu)
    WickEditor Website
    WickReferenceModal.scss

    Stylings for the Wick Reference Modals
*/

.WickReferenceModal {
    &-body,
    &-footer,
    &-header {
        padding-left: 60px;
        padding-right: 60px;

        @media screen and (max-width: 991px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 575px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &-close {
        position: absolute;
        right: 0px;
        top: 18px;
        left: 95%;
        transform: translateX(-50%);
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        opacity: 1;
        color: #106546; //#1EE29A;
        transition: 0.5s;

        &:hover, &:focus {
            color: #0B563B !important; //#1BC687 !important;
        }
    }
    
    &-button {
        font: 600 16px 'Nunito Sans', sans-serif;
        padding: 0.5em;
        color: black;
        background-color: #1EE29A;
        border: none;
        border-radius: 5px;
        margin: 0 0.5em;
        box-shadow: 2px 3px #B4ECE6;
        transition: 0.5s;

        &:hover,
        &:focus {
            color: black;
            background-color: #1EE29A;
            transform: translateX(-3px) translateY(-2px);
            box-shadow: 5px 5px #1BC687;
        }

        &:active {
            color: black;
            background-color: #1EE29A !important;
            transform: translateX(3px) translateY(2px);
            transition: 0.25s;
        }

        &:active,
        &:active:focus {
            color: black !important;
            box-shadow: none !important;
        }
    }

    &-download {
        margin-bottom: 10px;
    }

    &-open {
        margin-bottom: 1em;
    }
    
    &-container {
        text-align: center;
        font-family: 'Nunito Sans', sans-serif;
    }

    &-description {
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        margin: 0;
    }

    // &-code {
    //     width: 100%;
    //     height: 80px;
    //     margin-bottom: 1.5em;
    // }

    &-syntaxblock {
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    }

    &-syntax {
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        margin: 0;
    }

    &-parameters {
        font-size: 16px;
        font-weight: 800;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    &-parameter-name {
        text-align: left;
        margin: 0;
        padding: 0;
        width: 10%;
    }

    &-parameter-type{
        text-align: left;
        margin: 0;
        padding: 0;
    }

    &-parameter-description {
        font-weight: 600;
        text-align: left;
        width: 90%;
        margin: 0 !important;
        padding: 0;
    }

    &-parameter-spacing {
        margin-bottom: 0.5em;
    }

    &-embed {
        max-height: 400px; 
        max-width: 400px;
        margin: 0 auto; 
    }

    &-embed div {
        border-radius: 3px;
        box-shadow: 2px 3px #B4ECE6;
        margin-bottom: 1em;
        // width: 100%;

    }

    &-infoContainer {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        padding-bottom: 15px;
        border: none;
    }

    &-header {
        justify-content: center;
        padding-bottom: 0;
        border: none;
    }

    &-number {
        color: #000000; //#C3C3C3;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }

    &-time {
        margin: 0 4px;
        text-align: left;
        font-size: 16px;
        font-weight: 600;
    }

    &-title {
        font-size: 26px;
        font-weight: 800;
    }

    &-underline {
        width: 48px;
        border: solid 2px #1EE29A;
        margin: 8px auto;
    }

    &-note {
        text-align: left;
        color: #AB2121;
        margin-bottom: 1.5em;
    }

    &-subheader {
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 0.1em;
    }

    &-pararow {
        margin-bottom: 1.5em;
    }

    &-coderow {
        margin-bottom: 1.5em;
    }
}

.ReactCodeMirror {
    width: 100%;
    font-size: 16px;

    word-wrap: break-word;
    word-break: break-all;
}

.ReactCodeMirror textarea{
    word-wrap: break-word;
    word-break: break-all;
}
.CodeMirror {
    width: 100% !important;
    height: auto !important;
    max-height: 8em !important;
    text-align: left;

    word-wrap: break-word;
    word-break: break-all;
}
.cm-keyword {
    color: #FC92B9 !important;
}
.cm-def {
    color: #FD9D30 !important;
}
.cm-atom {
    color: #C3A3FF !important;
}
.cm-comment {
    color: #B6B2AA !important;
}