/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    NewsCard.scss

    Stylings for the news cards on homepage
*/

.NewsCard {
    &-arrow {
        opacity: 0;
        transition: opacity 0.5s;

        @media screen and (max-width: 991px) {
            opacity: 1;
        }
    }

    &-container {
        justify-content: flex-end;
        font-family: 'Nunito Sans', sans-serif;
        text-align: left;
        border-width: 0;
        box-shadow: 2px 3px #B4ECE6;
        transition: 0.5s;

        &:hover , &:focus {
            cursor: pointer;
            box-shadow: 5px 5px #1EE29A;
            transform: translateX(-3px) translateY(-2px);
        }

        &:hover .NewsCard-footer .NewsCard-arrow {
            opacity: 1;
        }
    }

    &-date {
        margin-bottom: 0;
        font-size: 14px;
        color: #6C757D;
    }

    &-footer {
        display: flex;
        justify-content: space-between;
        border: none;
        background-color: inherit;
        padding-top: 0;
    }

    &-text {
        font-size: 12px;
        font-weight: 400;

        @media screen and (max-width: 575px) {
            font-size: 14px;
        }
    }

    &-title {
        font-size: 22px;
        font-weight: 700;
    }
}