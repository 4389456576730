/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    WickButton.scss

    Stylings for the Wick Buttons
*/

// this should at least be #073C26 or darker to meet AAA standard
$dark-text: #073C26;//#0A4D32; 

.WickButton {
    &-button {
        &--body {
            font: 600 16px 'Nunito Sans', sans-serif;
        }

        &--header {
            font: 600 22px 'Nunito', sans-serif
        }

        &--download {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            max-width: 340px;
            padding: 1rem 1.5rem;
        }

        &--smallDownload {
            font: 800 18px 'Nunito Sans', sans-serif;
        }

        &--thicc {
            padding-top: 14px;
            padding-bottom: 14px;
        }
    }

    &-button {
        color: $dark-text; // white;
        background-color: #1EE29A;
        border: none;
        box-shadow: 2px 3px #B4ECE6;
        transition: 0.5s;

        &:hover,
        &:focus {
            color: $dark-text;
            background-color: #1EE29A;
            transform: translateX(-3px) translateY(-2px);
            box-shadow: 5px 5px #1BC687;
        }

        &:active {
            color: $dark-text;
            background-color: #1EE29A !important;
            transform: translateX(3px) translateY(2px);
            transition: 0.25s;
        }

        &:active,
        &:active:focus {
            color: $dark-text !important;
            box-shadow: none !important;
        }
    }

    &-icon {
        width: 40px;
        height: auto;
        margin-right: 1rem;

    }
    
    &-text {
        max-width: 230px;
        overflow-wrap: break-word;
    }
}