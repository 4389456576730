/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    TutorialCard.scss

    Stylings for the Wick Homepage Preview Cards
*/

.TutorialCard {
    &-arrow {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 20px;
        opacity: 0;
        transition: opacity 0.5s;

        &--disabled {
            opacity: 0 !important;
        }

        @media screen and (max-width: 991px) {
            opacity: 1;
        }
    }

    &-arrowSpacer {
        height: 24px;
    }

    &-container,
    &-container--disabled {
        border-width: 0;
        box-shadow: 2px 3px #B4ECE6;
        transition: 0.5s;
        font-family: 'Nunito Sans', sans-serif;
    }

    &-container:hover, &-container:focus {
        cursor: pointer;
        box-shadow: 5px 5px #1EE29A;
        transform: translateX(-3px) translateY(-2px);

        & .TutorialCard-content .TutorialCard-arrow {
            opacity: 1;
        }
    }

    &-container--disabled {
        opacity: 0.5;
    }

    &-content {
        background-color: white;
        border-bottom-left-radius: 3px; // coded to fill the rounded space in the reactstrap card
        border-bottom-right-radius: 3px; // coded to fill the rounded space in the reactstrap card
    }

    &-text {
        font-size: 12px;
        font-weight: 400;
        margin: 0;

        @media screen and (max-width: 575px) {
            font-size: 14px;
        }
    }

    &-title {
        font-size: 16px;
        font-weight: 600;

        @media screen and (max-width: 575px) {
            font-size: 18px;
        }
    }
}
