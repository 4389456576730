/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    Header.scss

    Stylings for the Wick Header
*/

#Header {
    &-buttonRow {
        padding: 64px 0 12px;

        @media screen and (max-width: 991px) {
            padding-top: 52px;
        }
    }

    // Button Space Placeholder
    &-buttonSpacer {
        //height: 48px; // coded to match height of reactstrap button
        display: none;
    }

    &-buttonSubSpacer {
        padding-bottom: 52px;

        @media screen and (max-width: 991px) {
            padding-bottom: 16px;
        }
    }

    &-buttonSubtext {
        font: 400 16px 'Nunito', sans-serif;
        padding-bottom: 32px;
        margin: 0 auto;
        max-width: 540px;
        @media screen and (max-width: 540px) {
            max-width: 432px;
        }
    }

    &-image {
        display: flex; // force images to sit next to each other
        position: absolute;
        top: -16px;
        // center images
        left: 50%;
        transform: translateX(-50%);
    }

    &-imageContainer {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1; // force behind the Page title and Nav Bar
        width: 100%;
        height: 100%;
        overflow-x: hidden; // don't show parts of image offscreen
    }

    &-tagline {
        font: 400 16px 'Nunito', sans-serif;
        color: #004D2F; //#007a4b;
        margin-bottom: 0px;
    }

    &-taglineSpacer {
        padding-bottom: 22px;
    }

    &-titleRow {
        font: 400 60px 'Nunito', sans-serif;
        line-height: 40px;
        color: #0A4D32; //#1EE29A;
        padding-bottom: 20px;
        margin-bottom: 0px;
        box-shadow: #1EE29A;
    }

    &-subtitleRow {
        font: 400 20px 'Nunito Sans', sans-serif;
        padding-bottom: 40px;
    }
}