/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    ExampleCard.scss

    Stylings for the Wick Homepage Preview Cards
*/

.ExampleCard {
    &-arrow {
        margin-top: 4px;
        opacity: 0;
        transition: opacity 0.5s;

        @media screen and (max-width: 991px) {
            opacity: 1;
        }
    }

    &-container,
    &-container--disabled {
        border-width: 0;
        box-shadow: 2px 3px #B4ECE6;
        overflow: hidden;
        transition: 0.5s;
        font-family: 'Nunito Sans', sans-serif;
    }
    &-container:hover, &-container:focus {
        cursor: pointer;
        box-shadow: 5px 5px #1EE29A;
        transform: translateX(-3px) translateY(-2px);

        & .ExampleCard-content {
            transform: none;

            .ExampleCard-arrow {
                opacity: 1;
            }
        }
    }

    &-container--disabled {
        opacity: 0.5;
    }

    &-content {
        top: auto;
        background-color: white;
        overflow: hidden;
        border-bottom-left-radius: 3px; // coded to fill the rounded space in the reactstrap card
        border-bottom-right-radius: 3px; // coded to fill the rounded space in the reactstrap card
        transform: translateY(20px);
        transition: transform 0.5s;

        @media screen and (max-width: 991px) {
            transform: none;
        }
    }

    &-title {
        font-size: 16px;
        font-weight: 600;
        margin: 0;

        @media screen and (max-width: 575px) {
            font-size: 18px;
        }
    }
}
