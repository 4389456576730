/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    ContentLink.scss

    Stylings for the Wick Content Links
*/

.ContentLink {
    &-arrow {
        padding-top: 20px;
        opacity: 0;
        transition: opacity 0.5s;

        @media screen and (max-width: 991px) {
            opacity: 1;
        }
    }

    &-container {
        background-color: transparent;
        border: none;
        margin-bottom: 40px;
        font-family: 'Nunito Sans', sans-serif;
        transition: transform 0.5s;

        &:hover {
            cursor: pointer;
            transform: translateX(-3px) translateY(-2px);

            & .ContentLink-arrow {
                opacity: 1;
            }
        }
    }

    &-image {
        padding-bottom: 12px;
    }

    &-text {
        font-size: 16px;
        font-weight: 400;
    }

    &-title {
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 8px;
    }
}