/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    SupportWick.scss

    Stylings for the Wick Support Buttons
*/

.SupportWick {
    &-button {
        padding-bottom: 12px;
    }
}