/*
    Corey Emery (cemery@andrew.cmu.edu)
    WickEditor Website
    SupporterBrands.scss

    Stylings for the Wick Supporter Brand Logos
*/

.SupporterBrands {
    &-row {
        display: flex;
        justify-content: space-between;
    }

    &-spacer {
        padding-bottom: 28px;
    }
}

.SupporterBrands-row {
    justify-content: space-evenly;
}

.supporter-brand.small {
    max-height: 100px;
    max-width: 125px;
}

.supporter-brand.medium {
    max-height: 125px;
    max-width: 175px;
}

.supporter-brand.large {
    max-height: 175px;
    max-width: 225px;
}